import { Grid, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import CircularProgressBackdropSmall from '../../common/CircularProgressBackdropSmall';
import _ from 'lodash';
import { ProcessTable } from './ProcessTable';
import { getProcesses } from '../../../queries';

const SuperAdminHome = () => {
  const {
    loading: loadingProcesses,
    error: processQueryError,
    data: processesData,
  } = useQuery(getProcesses);

  if (loadingProcesses) {
    return <CircularProgressBackdropSmall />;
  }

  if (processQueryError) {
    return (
      <Typography variant="h6" color="error">
        Error loading processes
      </Typography>
    );
  }

  const processes = _.get(processesData, 'processes', []);

  const filteredProcesses = _.filter(
    processes,
    (process) => !_.isEmpty(process.workflowItems)
  );

  const processesTableData = filteredProcesses.map((process) => {
    return {
      seller: process.seller,
      good: process.bundle?.goods?.[0]?.name || 'N/A',
      processName: process.name || 'N/A',
      processId: process.id || 'N/A',
      processExternalId: process.externalId?.externalId || 'N/A',
    };
  });


  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh', width: '100%' }}
    >
      <ProcessTable processes={processesTableData}></ProcessTable>
    </Grid>
  );
};

export default SuperAdminHome;