import { ExternalId } from "../../interfaces"

// Processes Table Page
export interface ProcessPageTable {
  good: string
  processExternalId: string
  processId: number
  processName: string
  seller: Seller
}

export interface Seller {
  id?: number
  name?: string
  website?: string
  sellerPageMessage?: string
  startButtonText1?: string 
  startButtonText2?: string 
}

// Process Page
export interface Process {
  id: number
  name: string
  email: string
  externalId?: ExternalId
  seller?: Seller
  workflowItems?: WorkflowItem []
}

export interface WorkflowItem { 
  id: number
  name: string
  description: string
  phases?: Phase[]
}

export interface Phase {
  id: number
  name: string
  description?: string
  priority: number
  steps?: Step[]
}

export interface Step {
  id: number
  name: string
  priority: number
  phase: {
    id: number
  }
  description: string
  externalIds: ExternalId[]
}


export interface SelectedNode {
  id: string;
  name: string;
  priority: number
  description: string
  externalId?: string;
  expandable: boolean
  expanded: boolean
  color: string
}

export enum ExternalIdType {
  EcclesiaAppFlatFee = 'EcclesiaAppFlatFee',
  EcclesiaAppPercentageFee = 'EcclesiaAppPercentageFee',
  IsBuyerPassthrough = 'IsBuyerPassthrough',
  IsStripeCard = 'IsStripeCard',
  IsStripeAch = 'IsStripeAch',
  EEDistrictId = 'EEDistrictId',
  EEUserId = 'EEUserId',
  EEWhiteLabelId = 'EEWhiteLabelId',
  JiraProjectId = 'JiraProjectId',
  JiraIssueKey = 'JiraIssueKey',
  JiraWorkflowStepId = 'JiraWorkflowStepId',
  JiraIssueStatus = 'JiraIssueStatus',
  JiraTransitionId = 'JiraTransitionId',
  JiraCustomFieldId = 'JiraCustomFieldId',
  GoogleDocId = 'GoogleDocId',
  GoogleSheetId = 'GoogleSheetId',
  GoogleGroupId = 'GoogleGroupId',
  HellosignTemplateId = 'HellosignTemplateId',
  MailgunTemplateId = 'MailgunTemplateId',
  StripeConnectedAccountId = 'StripeConnectedAccountId',
  StripeProductTaxCode = 'StripeProductTaxCode',
  PaymentProcessId = 'PaymentProcessId',
  EcclesiaBuyerFlatFee = 'EcclesiaBuyerFlatFee',
  EcclesiaBuyerPercentageFee = 'EcclesiaBuyerPercentageFee',
  EcclesiaSellerFlatFee = 'EcclesiaSellerFlatFee',
  EcclesiaSellerPercentageFee = 'EcclesiaSellerPercentageFee',
  EcclesiaMinFee = 'EcclesiaMinFee',
  EcclesiaMaxFee = 'EcclesiaMaxFee',
}