import { Snackbar, Alert } from '@mui/material';

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
  isSuccess: boolean;
  isInModal?: boolean;
}

const CustomSnackbar = ({ open, message, onClose, isSuccess, isInModal } : CustomSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={isInModal ? { vertical: 'top', horizontal: 'right' } : { vertical: 'top', horizontal: 'center' }}
      sx={isInModal ? { top: '20px' } : {} }
    >
      <Alert
        onClose={onClose}
        severity={isSuccess ? 'success' : 'error'}
        sx={{ width: '100%', backgroundColor: isSuccess ? 'green' : 'red', color: 'white' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
