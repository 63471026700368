import { useState } from "react";
import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import { useLambdas } from "../../utils/lambdas";
import { useAuth0 } from "@auth0/auth0-react";
import CustomSnackbar from "../common/CustomSnackbar";

interface AddCommentModalInterface {
  jiraId?: string
}

const AddCommentModal = (props: AddCommentModalInterface) => {
  const { jiraId } = props;
  const { user } = useAuth0();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    isSuccess: true,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setComment("");
    setOpen(false);
  };

  const [{ loading: commentLoading }, sendCommentFromApp] = useLambdas(
    "sendCommentFromApp",
    user,
    {
      issueKey: jiraId,
      commentText: comment,
    }
  );

  const handleSendComment = async () => {
    if (!comment.trim()) {
      setSnackbarData({
        message: 'Comment cannot be empty.',
        isSuccess: false,
      });
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await sendCommentFromApp();
      if (response && response.status === 200) {
        setSnackbarData({
          message: 'Comment sent successfully!',
          isSuccess: true,
        });
        setSnackbarOpen(true);
        setTimeout(() => {
          handleClose();
        }, 1500);
      }
    } catch (error) {
      setSnackbarData({
        message: 'Error sending comment',
        isSuccess: false,
      });
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        sx={{
          mt: 1.5,
          mb: 1,
          px: 1.5,
          minHeight: "32px",
          alignSelf: "flex-start"
        }}
        onClick={handleOpen}
      >
        Add Comment
      </Button>

      <Modal open={open} onClose={handleClose} aria-labelledby="add-comment-modal">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "50vw", sm: 500 },
            minHeight: { xs: "70vh", sm: "500px" },
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="h6" component="h2">
              Add Comment
            </Typography>
          </Box>

          <Box sx={{ p: 2, flex: 1 }}>
            <TextField
              label="Reply to customer"
              multiline
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              sx={{
                bgcolor: "white",
                borderRadius: "4px",
                width: "50%",
              }}
              size="small"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              p: "16px 20px",
              borderTop: "1px solid #e0e0e0",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendComment}
              sx={{ borderRadius: "20px", px: 2, mr: 1 }}
            >
              {commentLoading ? "Saving..." : "Save changes"}
            </Button>

            <Button variant="outlined" onClick={handleClose} sx={{ borderRadius: "20px", px: 2 }}>
              Cancel
            </Button>
          </Box>
          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarData.message}
            onClose={handleCloseSnackbar}
            isSuccess={snackbarData.isSuccess}
            isInModal={true} 
          />
        </Box>
      </Modal>
    </div>
  );
};


export default AddCommentModal;
