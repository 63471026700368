import { gql } from '@apollo/client';


export const getSellers = gql`
  query {
    sellers {
      id
      name
      optionTemplates {
        id
      }
    }
  }
`;


export const getSingleSeller = gql`
  query GetSingleSeller($id: Int!) {
    seller(id: $id) {
    name
		externalId {
			externalId
			externalIdType
		}
		website
		timezone

		processes {
			id
			name
			email
			externalId {
				externalId	
			}
			bundle {
				id
				name
				goods {
				id
				name
				}
			}
			workflowItems {
				id
				name
				phases {
					id
					name
					steps {
						id
						name
					}
				}
			}
		}
		
		optionTemplates {
			id
			name
			isActive
			goodOptions {
				id
				good {
					id
				}
				category
				learnMoreLink
			}
		}
	}
}
`

const sellerFields = `
	id
	name
	website
	sellerPageMessage
	startButtonText1
	startButtonText2
`
export const getSellerBasicInfo = gql`
  query GetSeller($id: Int!) {
    seller(id: $id) {
		${sellerFields}
	}
}
`

export const updateSeller = gql`
  mutation updateSeller($seller: SellerUpdateInput!) {
    updateSeller(seller: $seller) { 
      ${sellerFields}
    }
  }`;
