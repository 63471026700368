import { useParams } from "react-router-dom"
import CircularProgressBackdropSmall from '../../common/CircularProgressBackdropSmall';
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { getSellerBasicInfo, updateSeller } from "../../../queries/seller";
import _ from 'lodash';
import { useEffect, useMemo, useState } from "react";
import { CircularProgressBackdrop } from "../../common";

export const EditSellerPage = () => {
    const { sellerId } = useParams()
    const {
        loading: isLoading,
        error,
        data: sellerData,
    } = useQuery(getSellerBasicInfo, {
        variables: {id: Number(sellerId)},
    })
    
    const [ saveSeller ] = useMutation(updateSeller)

    const seller = useMemo(() => _.get(sellerData, 'seller', {}), [sellerData]);

    const [formState, setFormState] = useState(() => ({
        name: seller?.name || '',
        website: seller?.website || '',
        sellerPageMessage: seller?.sellerPageMessage || '',
        startButtonText1: seller?.startButtonText1 || '',
        startButtonText2: seller?.startButtonText2 || '',
    }));
    
    useEffect(() => {
        if (seller) {
            setFormState({
                name: seller.name || '',
                website: seller.website || '',
                sellerPageMessage: seller.sellerPageMessage || '',
                startButtonText1: seller.startButtonText1 || '',
                startButtonText2: seller.startButtonText2 || '',
            });
        }
    }, [seller]);
    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    };

    const [ isSubmitting, setIsSubmitting ] = useState(false);
      
    if (isLoading) {
        return <CircularProgressBackdropSmall />;
    }
    
    if (error) {
        return (
          <Typography variant="h6" color="error">
            Error loading seller {sellerId}
          </Typography>
        );
    }

    const onSubmitClicked = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        try {
            await saveSeller({
                variables: {
                    seller: {
                        id: Number(sellerId),
                        name: formState.name.trim() || seller.name,
                        website: formState.website.trim() || seller.website,
                        sellerPageMessage: formState.sellerPageMessage ? formState.sellerPageMessage.trim() : seller.sellerPageMessage,
                        startButtonText1: formState.startButtonText1 ? formState.startButtonText1.trim() : seller.startButtonText1,
                        startButtonText2: formState.startButtonText2 ? formState.startButtonText2.trim() : seller.startButtonText2,
                    },
                },
            });
        } catch (err) {
            console.error(err);
        }
    
        setIsSubmitting(false);
    };
    
      
    return (
        <Box component="form" onSubmit={onSubmitClicked} sx={{ mb: 2 }}>
            <Grid container pt={2} alignItems="center">
                <Typography variant="h6" align="left">
                Edit Seller
                </Typography>
            </Grid>
            <Grid item pt={2} alignItems="center">
                <TextField
                    fullWidth={true}
                    required={true}
                    name="name"
                    value={formState.name}
                    onChange={handleInputChange}
                    label="Name"
                    id="name"
                />
            </Grid>
            <Grid item pt={2} alignItems="center">
                <TextField
                    fullWidth={true}
                    required={true}
                    name="website"
                    value={formState.website}
                    onChange={handleInputChange}
                    label="Website"
                    id="website"
                />
            </Grid>
            <Grid item pt={2} alignItems="center">
                <TextField
                    fullWidth={true}
                    required={false}
                    multiline={true}
                    name="sellerPageMessage"
                    value={formState.sellerPageMessage}
                    onChange={handleInputChange}
                    label="Seller Page Message"
                    id="sellerPageMessage"
                />
            </Grid>
            <Grid item pt={2} alignItems="center">
                <TextField
                    fullWidth={true}
                    required={false}
                    name="startButtonText1"
                    value={formState.startButtonText1}
                    onChange={handleInputChange}
                    label="1st Start Button Text"
                    id="startButtonText1"
                />
            </Grid>
            <Grid item pt={2} alignItems="center">
                <TextField
                    fullWidth={true}
                    required={false}
                    name="startButtonText2"
                    value={formState.startButtonText2}
                    onChange={handleInputChange}
                    label="2nd Start Button Text"
                    id="startButtonText2"

                />
            </Grid>
            <Grid item pt={2} alignItems="center">
                <CircularProgressBackdrop open={isSubmitting} />
                <Button
                    variant="contained"
                    type="submit"
                    disableElevation
                >
                    <Typography variant={'subtitle2'}>
                        Save changes
                    </Typography>
                </Button>
            </Grid>
        </Box>
    )
}