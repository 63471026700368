import { useMemo, useState } from "react";
import { Card, Typography, Button, Box, Collapse, useMediaQuery } from "@mui/material";
import ToolSectionTransactionHistory from "./ToolSectionTransactionHistory";
import { theme } from "../../utils/theme";
import { Transaction } from "../../interfaces";
import AddCommentModal from "./AddCommentModal";
import CircularProgressBackdropSmall from "../common/CircularProgressBackdropSmall";

interface ToolProps {
  transaction?: Transaction | null
  name ?: string 
  goodDisplayDescription?: string
}

const statusMap: { [key: string]: string } = {
  "NotStarted": "Not Started",
  "InProgress": "In Progress",
};

const hiddenDisplayMessages = new Set(['Send Invoice PDF to Jira', 'Send Contract PDF to Jira']);

const DetailsToolsSection = (props: ToolProps) => {
  const { name, transaction, goodDisplayDescription } = props;
  const [showDetails, setShowDetails] = useState(false);
  const isMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const [isMessagesLoading] = useState(false);

  const openDetails = () => {
    if (!showDetails && goodDisplayMessages.length > 0) {
      setShowDetails(true);
    }
  };
  const closeDetails = () => {
    setShowDetails(false);
  };

  const goodName = name;
  const transactionStatus = transaction?.status
  const jiraId = transaction?.externalId?.externalId || '';
  const goodDescription = transaction?.process?.bundle?.goods[0]?.description;

  const goodDisplayMessages = useMemo(() => {
    const workflowItems = transaction?.workflowItems?.map((item) => ({
      displayHistoryMessage: item?.name || undefined,
      date: item?.completionDate || item?.startDate || undefined,
    })) || [];
  
    const transactionSteps = transaction?.activities?.map((activity) => ({
      displayHistoryMessage: activity?.transactionStep?.name || undefined,
      date: activity?.transactionStep?.completionDate || activity?.transactionStep?.startDate || undefined,
    })) || [];
  
    const displayMessages = [...workflowItems, ...transactionSteps];
    const filteredDisplayMessages = displayMessages.filter(displayMessage => !hiddenDisplayMessages.has(displayMessage.displayHistoryMessage || '') && displayMessage.date);
    return filteredDisplayMessages.sort((a, b) =>
      new Date(b?.date ?? 0).getTime() - new Date(a?.date ?? 0).getTime()
    );
  }, [transaction?.workflowItems, transaction?.activities]);

  return (
    <Card
      variant='outlined'
      sx={{
        marginBottom: isMediumBreakpoint ? '20px' : '10px',
        padding: isMediumBreakpoint ? '45px' : '35px',
        borderColor: theme.palette.common.lightBlue,
        maxWidth: '625px',
      }}
      onClick={openDetails}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h5' color='theme.palette.common.navy'>
          {goodName}
        </Typography>

        {!showDetails && (
          <Typography variant='body1' color='theme.palette.common.blue'>
            {goodDisplayMessages.length > 0 ? statusMap[transactionStatus ?? ""] || transactionStatus || "No Status" : "No Request"}
          </Typography>
        )}
        {showDetails && (
          <Typography variant='caption' color='textSecondary'>
            {jiraId}
          </Typography>
        )}
      </Box>
  
      {showDetails && isMessagesLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', minHeight: '150px' }}>
          <CircularProgressBackdropSmall />
        </Box>
      ) : (
        showDetails && (
          <Collapse
            in={showDetails}
            timeout={{ enter: 500 }}
            easing={{ enter: 'ease-in-out' }}
            unmountOnExit
          >
            <Typography variant='body2' color='textSecondary' sx={{ marginTop: '10px' }}>
              {goodDisplayDescription || goodDescription}
            </Typography>
            {jiraId !== 'NOT_CONFIGURED' && <AddCommentModal jiraId={jiraId} />}
            <ToolSectionTransactionHistory    
              transaction={transaction || undefined} 
              goodDisplayMessages={goodDisplayMessages || goodDescription} 
              jiraId={jiraId}
            />
            <Button
              variant='text'
              color='primary'
              sx={{
                marginTop: '10px',
                padding: 0,
                fontWeight: 'bold',
                textTransform: 'none',
              }}
              onClick={closeDetails}
            >
              Hide history
            </Button>
          </Collapse>
        )
      )}
    </Card>
  );
};

export default DetailsToolsSection;
