import { Avatar, Box, Button, List, ListItem, Typography } from "@mui/material"
import { theme } from "../../utils/theme"
import { useLambdas } from "../../utils/lambdas"
import { useAuth0 } from "@auth0/auth0-react"
import { Transaction } from "../../interfaces";
import { getFirstValueIfPresent } from "../../utils/transactions";
import { AxiosPromise } from "axios";
import { useEffect, useState } from "react";
import CustomSnackbar from "../common/CustomSnackbar";
import CircularProgressBackdropSmall from "../common/CircularProgressBackdropSmall";


interface GoodDisplayMessage {
  displayHistoryMessage?: string
  date?: Date | string
  author?: string
}

interface TransactionHistoryProps {
  goodDisplayMessages: GoodDisplayMessage[]
  transaction?: Transaction
  jiraId?: String
}

const JIRA_NOT_CONFIGURED = 'NOT_CONFIGURED';

const goodDisplayMessageMapping: { [key: string]: string } = {
  'Open': 'Good Requested',
  'Handle Contracts': 'Contracts in Progress',
  'Contracts in Progress': 'Contract Approved'
};

const ToolSectionTransactionHistory = (props: TransactionHistoryProps) => {
  const { user } = useAuth0();
  const { goodDisplayMessages, transaction, jiraId } = props;
  const [isLocked] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    isSuccess: true,
  });
  const [allMessages, setAllMessages] = useState<GoodDisplayMessage[]>([]);

  const [isMessagesLoading, setIsMessagesLoading] = useState(true);

  const [, getComments] = useLambdas(
    "getComments",
    user,
    {
      issueKey: jiraId
    }
  );

  useEffect(() => {
    if (jiraId === JIRA_NOT_CONFIGURED) {
      setIsMessagesLoading(false);
      setAllMessages(goodDisplayMessages);
      return;
    }
    setIsMessagesLoading(true);

    getComments()
      .then((response) => {
        let formatted = [];
        if (response?.data) {
          formatted = response.data.map((comment: any) => ({
            displayHistoryMessage: comment.body,
            date: comment.created,
            author: comment.author,
          }));
        }

        const sortedMessages = [...goodDisplayMessages, ...formatted]
          .filter(msg => msg.date)
          .sort((a, b) => new Date(b.date ?? 0).getTime() - new Date(a.date ?? 0).getTime());
        setAllMessages(sortedMessages);
        const loadingDuration = 1000;
        setTimeout(() => {
          setIsMessagesLoading(false);
        }, loadingDuration);
      })
      .catch(() => {
        setAllMessages(goodDisplayMessages);
        setTimeout(() => {
          setIsMessagesLoading(false);
        }, 500);
      });
  }, [jiraId, goodDisplayMessages, getComments]);


  const [buttonStates, setButtonStates] = useState<Record<string, { loading: boolean; isLocked: boolean }>>({});

  const updateButtonState = (key: string, newState: Partial<{ loading: boolean; isLocked: boolean }>) => {
    setButtonStates((prev) => ({
      ...prev,
      [key]: { ...prev[key], ...newState },
    }));
  };

  const contractId = getFirstValueIfPresent(transaction, 'hellosignRequestId');

  const mailgunStep = transaction?.workflowItems
    ?.flatMap((workflowItem) =>
      (workflowItem.phases || []).flatMap((phase) =>
        (phase.steps || [])
          .filter((step) => step?.stepType?.eventDetailType === 'mailgun-send-invoice')
          .map((step) => ({
            transactionId: transaction?.id,
            stepId: step?.id,
          }))
          .filter((step) => step.stepId != null)
      )
    )
    ?.filter(Boolean) || [];

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [, remindContract] = useLambdas(
    'remindContract',
    user,
    {
      detail: { transactionId: transaction?.id || 0 },
      hellosignRequestId: contractId,
    }
  );

  const [, remindInvoiceEmail] = useLambdas(
    'mailgunSendEmail',
    user,
    mailgunStep[0] ? {
      "detail-type": "mailgun-send-invoice",
      source: "admin",
      detail: {
        transactionStepId: mailgunStep[0].stepId,
        transactionId: mailgunStep[0].transactionId,
        stepCompletionOverride: true
      }
    } : undefined
  );

  const handleLambdaCall = async (
    messageKey: string,
    lambda: () => AxiosPromise<void>,
    successMessage: string,
    errorMessage?: string
  ) => {
    updateButtonState(messageKey, { loading: true, isLocked: false });
    try {
      await lambda();
      setSnackbarData({ message: successMessage, isSuccess: true });
      setSnackbarOpen(true);
    } catch (error) {
      updateButtonState(messageKey, { loading: false, isLocked: true });
      setSnackbarData({
        message: errorMessage || 'An error occurred',
        isSuccess: false,
      });
      setSnackbarOpen(true);
    } finally {
      updateButtonState(messageKey, { loading: false });
    }
  };

  const buttonConfig: Record<string, { label: string; onClick: () => void; loading: boolean; disabled: boolean }> = {
    'Handle Contracts': {
      label: 'Resend Contract',
      onClick: () =>
        handleLambdaCall(
          'Handle Contracts',
          remindContract,
          'Resend email successful',
          'Contract can only be resent once per hour, please ask the buyer to check their spam'
        ),
      loading: buttonStates['Handle Contracts']?.loading || false,
      disabled: buttonStates['Handle Contracts']?.isLocked || false,
    },
    ...(mailgunStep && mailgunStep[0] ? {
      'Send Invoice Email': {
        label: 'Resend Invoice Email',
        onClick: () =>
          handleLambdaCall(
            'Send Invoice Email',
            remindInvoiceEmail,
            'Resend email successful',
            'Failed to resend invoice email'
          ),
        loading: buttonStates['Send Invoice Email']?.loading || false,
        disabled: buttonStates['Send Invoice Email']?.isLocked || false,
      }
    } : {})
  };

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase();
  };

  const renderedMessageKeys = new Set();

  return (
    <>
      {isMessagesLoading || allMessages.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            height: '10px',
            maxHeight: '10px'
          }}
        >
          <CircularProgressBackdropSmall />
        </Box>
      ) : (
        <List sx={{ marginTop: '20px' }}>
          {allMessages.map((goodDisplay, index) => {
            const messageKey = goodDisplay.displayHistoryMessage || '';
            const isFirstOccurrence = !renderedMessageKeys.has(messageKey);
            const hasButton = Boolean(buttonConfig[messageKey]);

            if (isFirstOccurrence) {
              renderedMessageKeys.add(messageKey);
            }

            const dateDisplay = goodDisplay.date
              ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }).format(new Date(goodDisplay.date)) +
              ' | ' +
              new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZoneName: 'short'
              }).format(new Date(goodDisplay.date))
              : 'No date found';

            return (
              <ListItem
                key={index}
                divider
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {goodDisplay.author && (
                    <Avatar sx={{ bgcolor: 'gray', width: 38, height: 38 }}>
                      {getInitials(goodDisplay.author)}
                    </Avatar>
                  )}

                  <Typography
                    variant="body2"
                    sx={{
                      maxWidth: '50%',
                      color: theme.palette.common.lightGrey,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {goodDisplayMessageMapping[messageKey] || goodDisplay.displayHistoryMessage}
                  </Typography>
                  {isFirstOccurrence && (
                    <Typography variant="caption" sx={{ color: theme.palette.common.lightGrey }}>
                      {dateDisplay}
                    </Typography>
                  )}
                </Box>
                {isFirstOccurrence && hasButton && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={buttonConfig[messageKey].onClick}
                    disabled={buttonConfig[messageKey].loading || isLocked}
                    sx={{
                      backgroundColor: '#3b82f6',
                      color: 'white',
                      textTransform: 'none',
                      marginTop: '12px',
                      marginBottom: '8px',
                      padding: '4px 8px',
                      minHeight: '28px',
                      alignSelf: 'flex-start',
                      minWidth: '120px',
                    }}
                  >
                    {buttonConfig[messageKey].loading ? 'Loading...' : buttonConfig[messageKey].label}
                  </Button>
                )}
              </ListItem>
            );
          })}
          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarData.message}
            onClose={handleCloseSnackbar}
            isSuccess={snackbarData.isSuccess}
          />
        </List>
      )}
    </>
  );
};

export default ToolSectionTransactionHistory