import { Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProcessPageTable } from "../superadmin.types";
import { SuperadminStyledTableCell, SuperadminTableHeaderTypography } from "../SuperAdminStyledComponents";

interface ProcessTableProps {
  processes: ProcessPageTable[]
}

type Order = 'asc' | 'desc';

export const ProcessTable = ({processes}: ProcessTableProps) => {

  const navigate = useNavigate();
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ProcessPageTable>('seller');

  const handleSort = (event: React.MouseEvent, property: keyof ProcessPageTable) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedProcesses = [...processes].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
    return 0;
  });



  return (
    <TableContainer component={Paper}>
      <Table sx={{ tableLayout: 'fixed' }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              cursor: 'default',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <SuperadminStyledTableCell>
              <TableSortLabel
                active={orderBy === 'seller'}
                direction={orderBy === 'seller' ? order : 'asc'}
                onClick={(event) => handleSort(event, 'seller')}
              >
                <SuperadminTableHeaderTypography>
                  Seller
                </SuperadminTableHeaderTypography>
              </TableSortLabel>
            </SuperadminStyledTableCell>

            <SuperadminStyledTableCell>
              <TableSortLabel
                active={orderBy === 'good'}
                direction={orderBy === 'good' ? order : 'asc'}
                onClick={(event) => handleSort(event, 'good')}
              >
                <SuperadminTableHeaderTypography>
                  Good
                </SuperadminTableHeaderTypography>
              </TableSortLabel>
            </SuperadminStyledTableCell>

            <SuperadminStyledTableCell>
              <TableSortLabel
                active={orderBy === 'processName'}
                direction={orderBy === 'processName' ? order : 'asc'}
                onClick={(event) => handleSort(event, 'processName')}
              >
                <SuperadminTableHeaderTypography>
                  Process Name
                </SuperadminTableHeaderTypography>
              </TableSortLabel>
            </SuperadminStyledTableCell>
            <SuperadminStyledTableCell>
              <TableSortLabel
                active={orderBy === 'processExternalId'}
                direction={orderBy === 'processExternalId' ? order : 'asc'}
                onClick={(event) =>
                  handleSort(event, 'processExternalId')
                }
              >
                <SuperadminTableHeaderTypography>
                  Process External ID
                </SuperadminTableHeaderTypography>
              </TableSortLabel>
            </SuperadminStyledTableCell>
            <SuperadminStyledTableCell>
              <TableSortLabel
                active={orderBy === 'processId'}
                direction={orderBy === 'processId' ? order : 'asc'}
                onClick={(event) => handleSort(event, 'processId')}
              >
                <SuperadminTableHeaderTypography>
                  Process ID
                </SuperadminTableHeaderTypography>
              </TableSortLabel>
            </SuperadminStyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedProcesses
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((process, index) => (
              <TableRow
                key={index}
              >
                <SuperadminStyledTableCell
                  onClick={() =>
                    navigate(`/superadmin/seller/${process.seller.id}`)
                  }
                >
                  {process.seller.name}
                </SuperadminStyledTableCell>
                <SuperadminStyledTableCell>
                  {process.good}
                </SuperadminStyledTableCell>
                <SuperadminStyledTableCell
                  onClick={() =>
                    navigate(`/superadmin/process/${process.processId}`)
                  }
                >
                  {process.processName}
                </SuperadminStyledTableCell>
                <SuperadminStyledTableCell>
                  {process.processExternalId}
                </SuperadminStyledTableCell>
                <SuperadminStyledTableCell
                  onClick={() =>
                    navigate(`/superadmin/process/${process.processId}`)
                  }
                >
                  {process.processId}
                </SuperadminStyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={[10, 20, 100]}
        count={processes.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );

}